<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCard>
          <template v-slot:title>
            {{
              operation != null ?
                ( operation === 'add' ? 'Add Guest User' : ( operation === 'detail' ? 'Guest User Detail'
                  : 'Edit Guest User' ) ) : operationTitle
            }}
            <p><small>View and Edit Company Profile</small></p>
          </template>
          <template v-slot:toolbar v-if="operation === null">
            <b-input-group-append>
              <b-button class="btn btn-primary font-weight-bolder"
                        v-if="$global.hasPermission('companyprofilesstore')"
                        @click="handleChangeOperations('add')"
                        v-b-tooltip.hover :title="`Add New`"
                        variant="success"><i class="fa fa-plus fa-sm"></i> Add New
              </b-button>
              <!--                            <b-button variant="info"-->
              <!--                                      class="ml-2 filter-button"-->
              <!--                                      v-b-tooltip.hover :title="`Filter`"-->
              <!--                                      @click="filters.visible = !filters.visible">-->
              <!--                                <i class="fa fa-filter fa-sm"></i> Filter-->
              <!--                            </b-button>-->
            </b-input-group-append>
          </template>
          <template v-slot:toolbar v-else>
            <b-button class="mr-2 mt-3" @click="handleSubmitOperation"
                      size="sm"
                      variant="primary"
                      :disabled="global.pendingRequests > 0"
            >
              <i v-show="global.pendingRequests > 0"
                 class="fa fa-spin fa-spinner"></i>
              <i class="fa fa-save fa-sm"></i>
              Save
            </b-button>
            <b-button variant="danger" size="sm" class="mt-3"
                      @click="handleOperationClose()">
              <i class="fa fa-arrow-left fa-sm"></i> Cancel
            </b-button>
          </template>

          <template v-slot:body>
            <v-card>
              <div class="company-profiles-table"
                   v-if="$global.hasPermission('companyprofilesview') && operation === null">


                <div class="w-100 mb-6">
                  <div class="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="dataTables_length"><label>Show
                        <b-form-select v-model="pagination.totalPerPage"
                                       class="datatable-select"
                                       :options="[10, 50, 100]"
                                       size="sm"></b-form-select>
                        entries</label></div>

                    </div>
                    <!--                                        <div class="col-sm-12 col-md-6 mt-2">-->
                    <!--                                            <span v-for="(value,key) in filters"-->
                    <!--                                                  :key="key">-->
                    <!--                                                <span v-if="_.isArray(value) && key !== 'visible'">-->
                    <!--                                                    <b-tag class="py-2 text-capitalize" v-if="value.length > 0"> {{$global.removeSymbols(key)}}-->
                    <!--                                                        <i class="fa fa-times-circle ml-2"-->
                    <!--                                                           @click="clearFilter(key, [])"></i>-->
                    <!--                                                    </b-tag>-->
                    <!--                                                </span>-->
                    <!--                                                <span v-else>-->
                    <!--                                                    <b-tag class="py-2"-->
                    <!--                                                           v-if="value && key !== 'visible'"> <span-->
                    <!--                                                        class="text-capitalize">{{$global.removeSymbols(key)}}</span>-->
                    <!--                                                    <i class="fa fa-times-circle ml-2"-->
                    <!--                                                       @click="clearFilter(key , null)"></i>-->
                    <!--                                                    </b-tag>-->
                    <!--                                                </span>-->
                    <!--                                            </span>-->
                    <!--                                        </div>-->
                    <div class="col-sm-12 col-md-6">
                      <div id="kt_datatable_filter" class="dataTables_filter"><label>Search:
                        <b-form-input type="search"
                                      class="form-control form-control-sm ml-2 w-25"
                                      label="Search"
                                      v-on:keyup.enter="handleSearch"
                                      v-model="search"></b-form-input>
                      </label>
                      </div>
                    </div>
                  </div>
                </div>

                <v-data-table
                  responsive
                  ref="table"
                  :headers="columns"
                  :items="dataSource"
                  :loading="loading"
                  :items-per-page="parseInt(pagination.totalPerPage)"
                  hide-default-footer
                  class="table table-head-custom table-vertical-center table-responsive"

                >
                  <template v-slot:item.id="record">
                    {{ dataSource.length - record.index }}
                  </template>
                  <template v-slot:item.logo="record">
                    <b-avatar v-if="record.item.logo" class="mt-1" size="5rem"
                              :src="record.item.logo.download_url"></b-avatar>
                    <b-avatar v-else></b-avatar>
                  </template>
                  <template v-slot:item.action="record" class="action-column">
                    <a size="sm" class="btn btn-icon btn-light btn-hover-primary btn-sm"
                       @click="handleChangeOperations('edit', record.item.id)">
                                                                                       <span
                                                                                         class="svg-icon svg-icon-md svg-icon-primary">
                                              <!--begin::Svg Icon-->
                                              <inline-svg
                                                src="/media/svg/icons/Communication/Write.svg"
                                                class="action-edit-button"
                                              ></inline-svg>
                                                                                         <!--end::Svg Icon-->
                                            </span>

                    </a>
                    <a class="ml-2 btn btn-icon btn-light btn-hover-primary btn-sm"
                       @click="handleDeleteOperation(record.item.id)">
                                                                                        <span
                                                                                          class="svg-icon svg-icon-md svg-icon-primary">
                                              <!--begin::Svg Icon-->
                                              <inline-svg
                                                src="/media/svg/icons/General/Trash.svg" class="action-delete-button"
                                              ></inline-svg>
                                                                                          <!--end::Svg Icon-->
                                            </span>
                    </a>
                  </template>
                </v-data-table>
                <div class="row">
                  <div class="pagination-box">
                    <b-pagination
                      v-model="pagination.current"
                      :total-rows="pagination.total"
                      :per-page="pagination.totalPerPage"
                      size="lg"
                    ></b-pagination>

                  </div>
                </div><!-- /.pull-right -->
              </div>
              <div v-show="operation !== null && operation !== 'show'">
                <div class="company-profiles-operation">
                  <b-container fluid>
                    <form @submit.prevent="handleSubmitOperation" autocomplete="off">
                      <b-row>
                        <b-col class="p-0" cols="12" md="12" lg="12" sm="12">
                          <b-row>
                            <b-col sm="4">
                              <b-form-group
                                label="Name Of Company *"
                                label-for="company_name"
                                :invalid-feedback="formErrors.first('company_name')"
                              >
                                <b-form-input
                                  id="company_name"
                                  v-model="formFields.company_name"
                                  type="text"
                                  :state="((formErrors.has('company_name') ? false : null))"
                                ></b-form-input>
                              </b-form-group>
                            </b-col><!--/b-col-->
                            <b-col sm="4">
                              <b-form-group
                                label="Company Owner *"
                                label-for="company_owner_name"
                                :invalid-feedback="formErrors.first('company_owner_name')"
                              >
                                <b-form-input
                                  id="company_owner_name"
                                  v-model="formFields.company_owner_name"
                                  type="text"
                                  :state="((formErrors.has('company_owner_name') ? false : null))"
                                ></b-form-input>
                              </b-form-group>
                            </b-col>
                            <b-col sm="4">
                              <b-form-group
                                label="SuperUser *"
                                label-for="user_id"
                                :invalid-feedback="formErrors.first('user_id')"
                              >
                                <b-form-select
                                  id="user_id"
                                  v-model="formFields.user_id"
                                  type="text"
                                  :options="dropdowns.superUsers"
                                  :state="((formErrors.has('user_id') ? false : null))"
                                />
                              </b-form-group>
                            </b-col><!--/b-col-->
                          </b-row><!--/b-row-->
                          <b-row>
                            <b-col sm="3">
                              <b-form-group
                                label="Email *"
                                label-for="email"
                                :invalid-feedback="formErrors.first('email')"
                              >
                                <b-form-input
                                  id="email"
                                  v-model="formFields.email"
                                  type="email"
                                  :state="((formErrors.has('email') ? false : null))"
                                ></b-form-input>
                              </b-form-group>
                            </b-col><!--/b-col-->
                            <b-col sm="3">
                              <b-form-group
                                label="Website *"
                                label-for="website"
                                :invalid-feedback="formErrors.first('website')"
                              >
                                <b-form-input
                                  id="website"
                                  v-model="formFields.website"
                                  type="url"
                                  :state="((formErrors.has('website') ? false : null))"
                                ></b-form-input>
                              </b-form-group>
                            </b-col><!--/b-col-->
                            <b-col sm="3">
                              <b-form-group
                                label="Website *"
                                label-for="website"
                                :invalid-feedback="formErrors.first('instagram')"
                              >
                                <b-form-input
                                  id="website"
                                  v-model="formFields.instagram"
                                  type="url"
                                  :state="((formErrors.has('instagram') ? false : null))"
                                ></b-form-input>
                              </b-form-group>
                            </b-col><!--/b-col-->
                            <b-col sm="3">
                              <b-form-group
                                label="Website *"
                                label-for="website"
                                :invalid-feedback="formErrors.first('facebook')"
                              >
                                <b-form-input
                                  id="website"
                                  v-model="formFields.facebook"
                                  type="url"
                                  :state="((formErrors.has('facebook') ? false : null))"
                                ></b-form-input>
                              </b-form-group>
                            </b-col><!--/b-col-->
                          </b-row>
                          <b-row>
                            <b-col sm="4">
                              <b-form-group
                                label="Country"
                                label-for="country_id"
                                :invalid-feedback="formErrors.first('country_id')"
                              >
                                <b-form-select
                                  v-model="formFields.country_id"
                                  :options="dropdowns.countries"
                                  :state="((formErrors.has('country_id') ? false : null))"
                                >
                                </b-form-select>
                              </b-form-group>
                            </b-col><!--/b-col-->
                            <b-col sm="4">
                              <b-form-group
                                label="State"
                                label-for="state_id"
                                :invalid-feedback="formErrors.first('state_id')"
                              >
                                <b-form-select
                                  v-model="formFields.state_id"
                                  :options=" _.filter(dropdowns.states, (item) => item.country_id === formFields.country_id)"
                                  :state="((formErrors.has('state_id') ? false : null))"
                                >
                                </b-form-select>
                              </b-form-group>
                            </b-col><!--/b-col-->
                            <b-col sm="4">
                              <b-form-group
                                label="City"
                                label-for="city"
                                :invalid-feedback="formErrors.first('city')"
                              >
                                <b-form-input
                                  id="city"
                                  v-model="formFields.city"
                                  type="text"
                                  :state="((formErrors.has('city') ? false : null))"
                                >
                                </b-form-input>
                              </b-form-group>
                            </b-col><!--/b-col-->
                          </b-row>
                          <b-row>
                            <b-col sm="4">
                              <b-form-group
                                label="Year Of Establishment *"
                                label-for="year_of_establishment"
                                :invalid-feedback="formErrors.first('year_of_establishment')"
                              >
                                <b-form-input
                                  id="year_of_establishment"
                                  v-model="formFields.year_of_establishment"
                                  type="text"
                                  :state="((formErrors.has('year_of_establishment') ? false : null))"
                                ></b-form-input>
                              </b-form-group>
                            </b-col>
                            <b-col sm="4">
                              <b-form-group
                                label="Activate Date *"
                                label-for="activation_date"
                                :invalid-feedback="formErrors.first('activation_date')"
                              >
                                <b-datepicker
                                  :date-format-options="{ year: 'numeric',month: 'short', day: 'numeric' }"
                                  id="activation_date"
                                  v-model="formFields.activation_date"
                                  type="text"
                                  :state="((formErrors.has('activation_date') ? false : null))"
                                />
                              </b-form-group>
                            </b-col>
                            <b-col sm="4">
                              <b-form-group
                                label="Deactivation Date"
                                label-for="deactivation_date"
                                :invalid-feedback="formErrors.first('deactivation_date')"
                              >
                                <b-datepicker
                                  :date-format-options="{ year: 'numeric',month: 'short', day: 'numeric' }"
                                  id="deactivation_date"
                                  v-model="formFields.deactivation_date"
                                  type="text"
                                  :state="((formErrors.has('deactivation_date') ? false : null))"
                                />
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col sm="4">
                              <b-form-group
                                label="Bill Plan"
                                label-for="bill_plan"
                                :invalid-feedback="formErrors.first('bill_plan')"
                              >
                                <b-form-select
                                  id="bill_plan"
                                  :options="[{value:'Free',text:'Free'},{value:'Premium',text:'Premium'}]"
                                  v-model="formFields.bill_plan"
                                  type="text"
                                  :state="((formErrors.has('bill_plan') ? false : null))"
                                ></b-form-select>
                              </b-form-group>
                            </b-col>
                            <b-col sm="4">
                              <b-form-group
                                label="PAN No"
                                label-for="pan_no"
                                :invalid-feedback="formErrors.first('pan_no')"
                              >
                                <b-form-input
                                  id="pan_no"
                                  v-model="formFields.pan_no"
                                  type="text"
                                  :state="((formErrors.has('pan_no') ? false : null))"
                                ></b-form-input>
                              </b-form-group>
                            </b-col>
                            <b-col sm="4">
                              <b-form-group
                                label="GST No"
                                label-for="gst_no"
                                :invalid-feedback="formErrors.first('gst_no')"
                              >
                                <b-form-input
                                  id="gst_no"
                                  v-model="formFields.gst_no"
                                  type="text"
                                  :state="((formErrors.has('gst_no') ? false : null))"
                                ></b-form-input>
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col sm="4">
                              <b-form-group
                                label="No. of Employee"
                                label-for="no_of_employee"
                                :invalid-feedback="formErrors.first('no_of_employee')"
                              >
                                <b-form-input
                                  id="no_of_employee" v-numericOnly.keyup
                                  v-model="formFields.no_of_employee"
                                  type="text"
                                  :state="((formErrors.has('no_of_employee') ? false : null))"
                                ></b-form-input>
                              </b-form-group>
                            </b-col>
                            <b-col sm="4">
                              <b-form-group
                                label="No. of Properties"
                                label-for="no_of_properties"
                                :invalid-feedback="formErrors.first('no_of_properties')"
                              >
                                <b-form-input
                                  :disabled="true"
                                  id="no_of_properties"
                                  v-model="formFields.no_of_properties"
                                  type="text"
                                  :state="((formErrors.has('no_of_properties') ? false : null))"
                                ></b-form-input>
                              </b-form-group>
                            </b-col>
                            <b-col sm="4">
                              <b-form-group
                                label="Auto delete bookings after x days"
                                label-for="auto_delete_bookings"
                                :invalid-feedback="formErrors.first('auto_delete_bookings')"
                              >
                                <b-form-input
                                  id="no_of_properties"
                                  v-model="formFields.auto_delete_bookings"
                                  type="text"
                                  :state="((formErrors.has('auto_delete_bookings') ? false : null))"
                                ></b-form-input>
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col sm="12">
                              <b-form-group
                                label="Address"
                                label-for="address"
                                :invalid-feedback="formErrors.first('address')"
                              >
                                <b-form-textarea
                                  id="address"
                                  v-model="formFields.address"
                                  type="text"
                                >
                                </b-form-textarea>
                                <b-form-invalid-feedback
                                  :state="(!(formErrors.has('address')))">
                                  {{ formErrors.first('address') }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col sm="6">
                              <b-form-group
                                label="Logo"
                                label-for="logo">
                                <b-form-file
                                  @change="onChangeFile"
                                  id="logo">
                                </b-form-file>
                                <img
                                  :src="getFile.download_url"
                                  class="img-responsive mt-1"
                                  height="100"
                                  v-if="getFile && getFile.download_url" width="100" />
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <hr />
                          <b-row class="operation-footer">
                            <b-col sm="12">
                              <b-button
                                size="sm"
                                type="submit"
                                variant="primary"
                                :disabled="global.pendingRequests > 0"
                              >
                                <i v-show="global.pendingRequests > 0"
                                   class="fa fa-spin fa-spinner"></i>
                                <i class="fa fa-save fa-sm"></i>
                                Save
                              </b-button>

                              <b-button variant="danger" class="ml-3"
                                        size="sm"
                                        @click="handleOperationClose()">
                                <i class="fa fa-arrow-left fa-sm mr-1"></i> Cancel
                              </b-button>
                            </b-col>
                          </b-row>
                        </b-col><!--/b-col-->
                      </b-row><!--/b-row-->
                    </form><!--/form-->
                  </b-container><!--/b-container-->
                </div><!--/.company-profiles-operation-->
              </div>
            </v-card>
          </template>
        </KTCard>
        <div class="filter-container">
          <div id="filter_list"
               ref="company_profiles_list"
               v-show="!operation &&filters.visible"
               :class="filters.visible ? 'offcanvas offcanvas-right p-10 offcanvas-on' :'offcanvas offcanvas-right p-10'"
          >
            <form @submit.prevent="loadList(listQueryParams, true)" autocomplete="off">
              <b-row>
                <b-col sm="12" class="text-right">
                  <a
                    href="javascript:;"
                    @click="filters.visible = !filters.visible"
                    class="btn btn-xs btn-icon btn-light btn-hover-primary text-right"
                  >
                    <i class="ki ki-close icon-xs text-muted"></i>
                  </a>
                </b-col>
                <b-col sm="12">
                  <b-form-group
                    label="Bill Plan"
                    label-for="bill_plan">
                    <b-form-input id="filter-title" v-model="filters.bill_plan"
                                  :options="[{value:'Free',text:'Free'},{value:'Premium',text:'Premium'}]"
                                  class="mb-2"></b-form-input>
                  </b-form-group>
                </b-col><!--/b-col-->
              </b-row>
              <div class="drawer-footer">
                <b-button @click="handleResetFilterClick()" size='sm' variant="info">
                  Clear All
                </b-button>
              </div><!-- /.drawer-footer -->
            </form>
          </div>
        </div>
        <!-- /.filter-container -->
      </div>

    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import KTCard from "@/view/content/Card.vue";
import ListingMixin from "@/assets/js/components/ListingMixin";
import Error from "@/core/services/Error";
import { request } from "@/core/services/Request";

const DEFAULT_FORM_STATE = {
  company_name: null,
  email: null,
  company_owner_name: null,
  address: null,
  user_id: undefined,
  instagram: undefined,
  facebook: undefined,
  country_id: undefined,
  state_id: undefined,
  city: null,
  website: null,
  year_of_establishment: null,
  pan_no: null,
  gst_no: null,
  activation_date: null,
  deactivation_date: null,
  no_of_employee: null,
  no_of_properties: null,
  auto_delete_bookings: null,
  bill_plan: undefined,
  logo: null,
  _method: 'post',
};

const DEFAULT_FILTER_STATE = {
  bill_plan: null,
};

const COLUMN_DEFINATION = () => [
  {
    text: "#",
    sortable: false,
    value: 'id',
  },
  {
    text: "Logo",
    sortable: false,
    value: 'logo',
  },
  {
    text: "Company Name",
    sortable: false,
    value: 'company_name',
  },
  {
    text: "Company Owner Name",
    sortable: false,
    value: 'company_owner_name',
  },
  {
    text: "Email",
    sortable: false,
    value: 'email',
  },
  {
    text: "Website",
    sortable: false,
    value: 'website',
  },
  {
    text: "No. of Banquets",
    sortable: false,
    value: 'no_of_banquets',
  },
  {
    text: "Action",
    value: "action",
    sortable: false,
  },

];

export default {
  mixins: [ListingMixin],
  data() {
    return {
      operationTitle: 'Company Profiles',
      formFields: { ...DEFAULT_FORM_STATE },
      filters: { ...DEFAULT_FILTER_STATE },
      listUrl: '/company/profiles',
      columns: COLUMN_DEFINATION(this),
      dropdowns: {
        superUsers: [],
        countries: [],
        states: [],
      },
      file: null,
    };
  },
  components: {
    KTCard,
  },
  mounted() {
    this.getSuperUser();
    this.getCountry();
    this.getState();

    if ( this.$route.query && this.$route.query.operation && this.$route.query.id ) {
      this.handleEditOperation(this.$route.query.id);
    }
  },
  methods: {
    async onChangeFile(e) {
      this.file = await this.uploadFile(e.target.files[0]);
    },
    async uploadFile(file) {
      let self = this;
      let formData = new FormData();
      formData.append('file', file);

      try {
        const response = await request({
          url: '/upload',
          method: 'post',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data'
          },
        });
        const { data } = response.data;
        return data;

      } catch (error) {
      }
    },
    afterCloseOperation() {
      this.formFields = { ...DEFAULT_FORM_STATE };
    },
    handleChangeOperations(operation = 'add', operationDataId = null) {
      this.operationTitle = ( operation === 'add' ? 'Add Company Profile' : 'Edit Company Profile' );
      this.$router.replace({
        query: Object.assign({},
          this.$route.query,
          {
            ...this.listQueryParams,
            operation: operation,
            id: operationDataId,
          },
        ),
      });
    },
    async handleSubmitOperation() {
      this.formErrors = new Error({});

      this.formFields.logo = this.getFile;
      try {
        const response = await request({
          url: this.formFields.id ? 'company/profiles/update' : 'company/profiles/create',
          method: 'post',
          data: this.formFields,
        });
        const { data } = response.data;

        let self = this;
        if ( this.formFields.id ) {
          this.$global.itemUpdated();
        } else {
          this.$global.itemAdded();
        }

        if ( this.formFields.logo ) {
          await self.uploadFile(data.id);
        }

        this.handleOperationClose();
      } catch (error) {
        if ( error.request && error.request.status && error.request.status === 422 ) {
          this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
          return false;
        }
        this.$global.handleServerError(error);
      }

    },
    async handleDeleteOperation(id) {
      let deleteResponse = await this.$root.$confirm('Delete Data Confirmation', "Are you sure you want to delete this ?", {
        primaryBtnText: 'Yes',
        secondaryBtnText: 'No'
      });
      if ( deleteResponse ) {
        try {
          const response = await request({
            method: 'post',
            url: '/company/profiles/delete',
            data: {
              id: id,
            },
          });
          this.loadList(this.listQueryParams);
          this.$global.itemDeleted();
        } catch (errors) {
          this.$global.handleServerError(errors);
        }
      }
    },
    async handleEditOperation(id) {
      try {
        const response = await request({
          method: 'get',
          url: `/company/profiles/detail/${ id }`,
        });
        this.operationTitle = 'Edit Company Profile';
        const { data } = response.data;
        this.formFields = {
          ...data,
          user_id: data.user ? data.user.id : undefined
        };
        this.file = data.logo ? data.logo.download_url : null;

      } catch (e) {
        this.$global.itemEditFails();
        this.formFields = { ...DEFAULT_FORM_STATE };
      }
    },
    async getSuperUser() {
      try {
        const response = await request({
          url: '/dropdowns/super/user',
          method: 'post',
        });

        const { data } = response.data;
        this.dropdowns.superUsers = data.map((item) => {
          return {
            value: item.id,
            text: item.name,
          };
        });
      } catch (e) {

      }
    },
    async getCountry() {
      try {
        const response = await request({
          url: '/dropdowns/countries',
          method: 'post',
        });

        const { data } = response.data;
        this.dropdowns.countries = data.map((item) => {
          return {
            value: item.id,
            text: item.name,
          };
        });
      } catch (e) {

      }
    },
    async getState() {
      try {
        const response = await request({
          url: '/dropdowns/states',
          method: 'post',
        });

        const { data } = response.data;
        this.dropdowns.states = data.map((item) => {
          return {
            value: item.id,
            text: item.name,
            country_id: item.country_id,
          };
        });
      } catch (e) {

      }
    },
    handleResetFilterClick() {
      this.filters = { ...DEFAULT_FILTER_STATE };
      this.isFilterApplied = 'reset';
      this.checkFilterCountStatus();
    },
  },
  computed: {
    ...mapState([
      'global',
    ]),
    getFile() {
      return this.file;
    }
  },
};
</script>
